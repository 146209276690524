import React from 'react';
import { Button, Divider, Grid, Segment, Header, Icon } from 'semantic-ui-react'
import {BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import CustomerUploadLink from './CustomerUploadLink'
//import CustomerDownloadLink from './CustomerDownloadLink'
import UploadDialog from './MA_UploadDialog'

function SelectBox({sel}){
  return (
  <Segment placeholder>
      <Grid columns={2} relaxed='very' stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <Header icon>
              <Icon name='cloud download' />
              Kunden Download
            </Header>
            <Link to="/downloadlink"> 
            <Button  content='Link erstellen' size='big' color='violet'/> 
            </Link>
          </Grid.Column>
          <Grid.Column verticalAlign='middle'>
            <Header icon>
                <Icon name='cloud upload' />
                Kunden Upload
              </Header>
            <Link  to="/uploadlink"> 
              <Button  content='Link erstellen' size='big' color='purple'/>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider vertical>Oder</Divider>
    </Segment>
  )
}


function MenuGenerateLink(){
  return (
      <BrowserRouter>
        <Segment raised padded >
            <Switch>
              <Route exact path="/" component={SelectBox}></Route>
              <Route path="/downloadlink"><Download/></Route>
              <Route path="/uploadlink" component={Upload}></Route>
            </Switch>
        </Segment>
      </BrowserRouter>
  )
}
export default MenuGenerateLink;



const Download = ()=>{
  return (
    <div>
      <div >
        <h1 style={{'float':'left', 'clear':'both'}} className="ui header">Kunden Download-Link erstellen</h1>
        <Link exact to="/">
          <Button animated style={{'float':'right'}}>
            <Button.Content visible>
              <Icon name='close' />
            </Button.Content>
            <Button.Content hidden>Zurück</Button.Content>
          </Button>
        </Link>
      </div>
      <UploadDialog/>
      { /* <CustomerDownloadLink/> */}
    </div>
    )
}

const Upload = ()=>{
  return (
         <div>
          <div>
            <h1 style={{'float':'left', 'clear':'both'}} className="ui header">Kunden Upload-Link erstellen</h1>
            <Link exact to="/">
              <Button animated style={{'float':'right'}}>
                <Button.Content visible>
                  <Icon name='close' />
                </Button.Content>
                <Button.Content hidden>Zurück</Button.Content>
              </Button>
            </Link>
          </div>
          <CustomerUploadLink />
        </div>
    )
}

