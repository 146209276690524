import React, { useState, useEffect } from 'react';
import { Placeholder, List,  Segment, Label, Button, Modal, Icon, Item, Divider } from 'semantic-ui-react'
import { get_private_UUID_list,get_private_file_list, toggle_private_link_type } from './aws_api_calls'
import MA_UploadDialog from './MA_UploadDialog'

// Master URL
import {URL} from '../App.js'

export default function LinkList(){
  const [UUID_List, appendUUID] = useState([]);
  const [fileList, addFiles] = useState([]);
  const [showUploadDia, toggleUploadDia] = useState(false);
  const [loading, setLoading] = useState(true);
  //var UUID_Dates = {}

  useEffect( ()=>{
    (async () =>{

      // Get UUID List
      let r = await get_private_UUID_list()
      appendUUID(r)

      // very cool sort by expirationDate Function
      r.data.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.expirationDate) - new Date(a.expirationDate);
      });


      // Get Expiry Date for sorting ( not implemented yet )
/*      r.data.forEach((el) => {
        UUID_Dates.[el.id] = el.expirationDate
      }) */
     // console.log(UUID_Date)

      // Get files associated with UUIDs
      let promises = []
      r.data.forEach((el) => {promises.push( get_private_file_list(el.id) ) })

      var temp_file_list = []
      await Promise.all(promises).then((resp)=>{
        // we receive a dangerously deep object back from aws??
        Object.entries(resp).forEach(el =>{
          Object.entries(el).forEach((o,idx) => {
            if ( idx % 2  !== 0 ) {
              const ent = Object.entries(o[1])
              const uuid = ent[0][0]
              const files =  ent[0][1].data.files
              temp_file_list.push({[uuid]:[files],})
            }
          })
        })
        addFiles(temp_file_list)
      })
      setLoading(false)
    })()
  },[])

  if ( loading)
    return (
      <div>
      <Segment>   
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          </Placeholder>
        </Segment>
      <Segment>   
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          </Placeholder>
        </Segment>
      <Segment>   
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          </Placeholder>
        </Segment>
        </div>
      )

  return (
      <List divided verticalAlign='middle'>
        {UUID_List.data.map((el)=>{
          let files =  fileList.filter( (data) => el.id === Object.keys(data)[0] );
          return <ListItems el={el} key={el.id} files={files} toggle={toggleUploadDia} showUploadDia={showUploadDia} />
        }
      )}
      </List>
  )
}

function ListItems(props) {
  const [linkType, setLinkType] = React.useState(props.el.type)

  let ret = null;

  if (linkType === "upload") { // Upload Links
    if (props.files[0] !== 'undefined')
      ret = <ListItems_Upload el={props.el} files={props.files[0]} uuid={props.el.id} key={props.el.id} toggle={props.toggleUploadDia} showUploadDia={props.showUploadDia} setType={setLinkType}/>
  }

  if (linkType === 'download') { // Download Links with File List
    if (props.files[0] !== 'undefined')
      ret = <ListItems_Download el={props.el} files={props.files[0]} uuid={props.el.id} key={props.el.id} toggle={props.toggleUploadDia} showUploadDia={props.showUploadDia} setType={setLinkType}/>
  }

  return <Segment key={props.uuid} color={(linkType === "download") ? "violet" : "pink"}>
      {ret}
      <Label attached='bottom right'>Gültig bis: {humanDateTime(props.el.expirationDate)}</Label>
    </Segment>;
}


function ListItems_Download(props){
  let fileList = Object.values(props.files)
  return fileList.map((files,idx) => {
    return (
        <Item.Group key={props.uuid}>
          <Item>
            <Icon circular color='violet' inverted name='cloud download' size='big' />&nbsp;&nbsp;&nbsp;
            <Item.Content>
              <Button floated="right" icon onClick={() => toggle_private_link_type(props.el.id).then((ret) => props.setType(ret.data))}>
                <Icon name="reply" />
              </Button>
              <Item.Header as='a' href={URL.dl_url + props.el.id} target="_blank">{URL.dl_url + props.el.id}</Item.Header>
              <Item.Meta>
                <Item.Extra>
                  {
                    (typeof props.el.clientemail !== "undefined") && props.el.clientemail !== "undefined" &&
                    <Label>
                      <Icon name='mail' /> {props.el.clientemail}
                    </Label>
                  }
                </Item.Extra>
              </Item.Meta>
              {
                (typeof props.el.comment !== "undefined") && props.el.comment !== "undefined" &&
                <Item.Description>
                  {props.el.comment}
                </Item.Description>
              }

              <Divider />

              <List divided relaxed>
                <ListSingleFiles key={idx} files={files} />
              </List>

              <Divider />

              <UploadModal uuid={props.uuid} />

            </Item.Content>
          </Item>
        </Item.Group>
      )
    })
}



function UploadModal(props) {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<div><Button icon labelPosition='left'><Icon name="plus"></Icon>Datei hinzufügen</Button></div>}
    >
      <Modal.Header>Dateien wählen</Modal.Header>
      <Modal.Content image>
        <MA_UploadDialog uuid={props.uuid} />
       
      </Modal.Content>
      <Modal.Actions>
        <Button icon labelPosition='right' color='red' onClick={() => setOpen(false)}>
          <Icon name="close"></Icon>
          schließen
        </Button>
       </Modal.Actions>
    </Modal>
  )
}

function ListSingleFiles(props){
  if ( props.files[0].length > 0){
      return props.files[0].map((el,idx) => {
        return (
          <List.Item key={idx}>
            <List.Icon name='file outline' size='large' verticalAlign='middle' />
            <List.Content>
              <List.Header as='a' href={el.url} download>{el.file}</List.Header>
              <List.Description>{humanFileSize(el.size)} - {humanDateTime(el.lastModified)}</List.Description>
            </List.Content>
          </List.Item>
        )
      })
  } else {
      return (
        <List.Item>
          <List.Icon name='folder outline' size='large' verticalAlign='middle' />
          <List.Content>
            <List.Header>Keine Dateien vorhanden</List.Header>
            <List.Description></List.Description>
          </List.Content>
        </List.Item>
      )
  }
}


function ListItems_Upload(props) {
  let fileList = Object.values(props.files)
  return fileList.map((files,idx) => {
  return (
        <Item.Group key={props.uuid}>
          <Item>
            <Icon circular color='pink' inverted name='cloud upload' size='big' />&nbsp;&nbsp;&nbsp;
            <Item.Content>
              <Button floated="right" icon onClick={() => toggle_private_link_type(props.el.id).then((ret) => props.setType(ret.data))}>
                <Icon name="share" />
              </Button>
              <Item.Header as='a' href={URL.ul_url + props.el.id} target="_blank">{URL.ul_url + props.el.id}</Item.Header>
              <Item.Meta>
                <Item.Extra>
                  {
                    (typeof props.el.clientemail !== "undefined") && props.el.clientemail !== "undefined" &&
                    <Label>
                      <Icon name='mail' /> {props.el.clientemail}
                    </Label>
                  }
                </Item.Extra>
              </Item.Meta>
              {
                (typeof props.el.comment !== "undefined") && props.el.comment !== "undefined" &&
                <Item.Description>
                  {props.el.comment}
                </Item.Description>
              }

              <Divider />

              <List divided relaxed>
                <ListSingleFiles key={idx} files={files} />
              </List>
            </Item.Content>
          </Item>
        </Item.Group>

    )
  })
}

// human readable filesize
function humanFileSize(size) {
  var i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

function humanDateTime(date) {
  let options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false,
    timeZone: 'Europe/Berlin'
  };

  return new Intl.DateTimeFormat("de-DE", options).format(new Date(date))
}