import React from 'react';
import { Container, Message, Progress, Button, Grid, Icon, Label } from 'semantic-ui-react'
import axios from 'axios'
import { create_public_download_upload_uuid as getUUID, get_presigend_multipart_upload_URL, get_presigend_upload_URL, complete_presigend_multipart_upload } from './aws_api_calls'
import { isValidUUID, isValid_AWS_URL } from '../helper_functions.js'
import Dropzone from 'react-dropzone'
import {URL} from '../App.js' // Master URL

//Amplify.configure(config);
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

// https://www.pluralsight.com/guides/uploading-files-with-reactjs

const FILE_CHUNK_SIZE = 1000000000; // 1000MB chucks

class UploadDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadPending: false,
      successfullUploaded: false
    };

    this.onDrop = (files) => {this.setState({files: this.state.files.concat(files)})};

    this.uploadFiles = this.uploadFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.uploadFailed = this.uploadFailed.bind(this);
  }

  removeFile(file){
    // console.log(file)
    let remainingArr = this.state.files.filter(data => data.name !== file.name);
    this.setState({files: remainingArr });
  }

  uploadFailed(msg){
    this.state.files.shift()
    this.setState({ 
      uploadPending: false,
      successfullUploaded: false,
      errMsg: msg,
    });
  }

  async uploadFiles() {
      // init upload
      this.setState({ uploadPending: true });

      if (typeof this.props.uuid !== 'undefined'){
        this.uploadManager(this.props.uuid)
      }

      if ( typeof this.props.uuid === 'undefined'){
        // call aws apis to get presigned upload urls
        getUUID('download' ).then(result => {
          //console.log( result.data )
          this.uploadManager(result.data.uuid)
        }).catch(e => {
          this.uploadFailed(e.message)
          console.error(e)
        })
      }
  }

  updateProgress(index, progress, bytes) {
    //var index = this.state.files.findIndex(x=> x.id === id);
    if (index < 0 || index >= this.state.files.length)
      console.log("file to update not found");
    else {
      const now = Date.now();
      var speed = this.state.files[index].speed;
      if(this.state.files[index].bytes && this.state.files[index].bytes < bytes)
        speed = (bytes - this.state.files[index].bytes) / ((now - this.state.files[index].lastBytesUpdate) / 1000);
      else
        speed = 0;

        this.setState({
          files: [
            ...this.state.files.slice(0,index),
            Object.assign(this.state.files[index], {progress: progress, bytes: bytes, lastBytesUpdate: now, speed: speed }),
            ...this.state.files.slice(index+1)
          ]
        });
    }
  }

  async uploadParts(file, urls, fileId, _this) {
    const keys = Object.keys(urls)
    const promises = []
    var progress = []
  
    for (const indexStr of keys) {
      const index = parseInt(indexStr)
      const start = index * FILE_CHUNK_SIZE
      const end = (index + 1) * FILE_CHUNK_SIZE
      const blob = index < keys.length
        ? file.slice(start, end)
        : file.slice(start)
  
      promises.push(axios.put(urls[index], blob, {
          onUploadProgress: (e) => {
            // axios has no idea what "this" is, so we use _this
            progress[index] = e.loaded;
            const sum = progress.reduce((partial_sum, a) => partial_sum + a,0);
            _this.updateProgress(fileId, Math.round(sum / file.size * 100), sum);
          }
        }))
    }
  
    const resParts = await Promise.all(promises)

    console.log(resParts);
  
    return resParts.map((part, index) => ({
      ETag: part.headers.etag,
      PartNumber: index + 1
    }))
  }

  async uploadManager(uuid) {

     if (isValidUUID(uuid) ) {
     // if (true ) {
      this.setState({uuid: uuid});

      // actual uploads
      this.state.files.forEach( (file, fileId) => {
        // add file to progress array
        this.updateProgress(fileId, 0);

        /*
        get_presigend_upload_URL(uuid, file.name).then( result => {

          if ( isValid_AWS_URL(result.data.url) ){
            //const formData = new FormData();
            //formData.append('file', file, file.name); // appending file

            axios.put(result.data.url, file, {
              //onUploadProgress: (e) => {this.setState({progress: Math.round(e.loaded / e.total * 100)}) }
              onUploadProgress: (e) => {
                // axios has no idea what "this" is, so we use _this
                _this.updateProgress(fileId, Math.round(e.loaded / e.total * 100));
              }
            }).then(res => {
              // remove uploaded fiel from list
              this.state.files.shift()
              this.setState({ 
                uploadPending: false,
                successfullUploaded: true,
              });
            }).catch(e => {
              this.uploadFailed(e.message)  
              console.error(e)
            })
          } else {
            this.uploadFailed(result.data.errorMessage,)
            console.error(result.data)
          }
         }); 
         */

         var chucks = Math.ceil(file.size / FILE_CHUNK_SIZE);
         //console.log("num of chucks", chucks);

         get_presigend_multipart_upload_URL(uuid, file.name, chucks).then( result => {
          this.uploadParts(file, result.data.parts, fileId, this).then( uploadResult => {
            complete_presigend_multipart_upload( uuid, file.name, result.data.uploadid, uploadResult ).then( r => {
              // remove uploaded fiel from list
              this.state.files.shift()
              this.setState({ 
                uploadPending: false,
                successfullUploaded: true,
              });
            });
          });
         })
      })
    } else { 
       this.setState({uploadPending: false, files: []})
       alert('UUID ungültig: ' + uuid) 
    }

  }

  render(){

    const styleObj ={'boxShadow': 'none'}
    const downloadlink = URL.dl_url + this.state.uuid

    // File List generieren
    const files = this.state.files.map(file => (
        <Grid key={file.name}>
          <Grid.Row  style={styleObj} >
            <Grid.Column>
              <Label>
                <Icon name="file"></Icon>
                {file.name}
                <Label.Detail>{humanFileSize(file.size)}</Label.Detail>
              </Label>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row  style={styleObj} >
            <Grid.Column width={file.progress ? 16 : 15}>
              <Progress percent={file.progress} progress={file.progress >= 0} autoSuccess active={file.progress >= 0} color='blue'>{ uploadBarText(file.bytes, file.size) }</Progress>
            </Grid.Column>
            {!file.progress &&
            <Grid.Column className="right aligned" width={1}>
                <i className="large red times circle link icon" onClick={()=>this.removeFile(file)}></i>
              { /* TODO: upload abbrechen 
                file.progress > 0 &&
                <Button info onClick={()=>this.removeFile(file)}>Cancel</Button>
              */}
            </Grid.Column>
            }
          </Grid.Row>
        </Grid>
   ));

    return (
      <Container>
          
          {/* https://react-dropzone.js.org/#section-class-components */}
          <Dropzone onDrop={this.onDrop} >
            {({getRootProps, getInputProps}) => (
                <div {...getRootProps()} className="ui placeholder segment" style={{clear:"both"}}>
                  <div className="ui icon header">
                    <i className="file outline icon"></i>
                    Dateien hinzufügen
                  </div>
                  <div className="ui primary button">auswählen</div>
                  <input  {...getInputProps()} />
                </div>
            )}
          </Dropzone>

          { /* Erfolg Meldung */
            this.state.successfullUploaded &&
            <Message positive>
              <Message.Header>Uploads beendet</Message.Header>
              <p><a size='huge' href={downloadlink} target="_blank" rel="noopener noreferrer">Download Link - {downloadlink}</a> </p>
            </Message>
           }

          { /* Fehler Meldung */
            this.state.errMsg && 
            <Message negative><Message.Header>Es gab Probleme</Message.Header> <p> {this.state.errMsg}</p></Message>
           }

          <div className="ui hidden divider"></div>

          { /* File List */
            this.state.files.length > 0 && <section>{files}</section> }
          
          { this.state.uploadPending && <Button basic loading> Loading </Button> }

          <div className="ui hidden divider"></div>
         
          {/* Upload starten */
           !this.state.uploadPending && ( this.state.files.length > 0 ) && <Button primary icon labelPosition='left' onClick={()=>this.uploadFiles()}><Icon name='plus' />Upload starten</Button> }
          
      </Container>
    )}
  }
  export default UploadDialog;

// human readable filesize
function humanFileSize(size) {
  var i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

// human readable transferspeed
function humanFileSpeed(size) {
  var i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(1) * 1 + ' ' + ['B/s', 'kB/s', 'MB/s', 'GB/s'][i];
};

function uploadBarText(progress, size) {
  if(size > 0 && progress > 0 && size > progress)
    return humanFileSize(progress) + " / " + humanFileSize(size-progress);
  else
    return "";
}