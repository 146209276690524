import axios from 'axios'
import { Auth } from 'aws-amplify';

/* Create JWT Token for API Gateway Authentication 
* @param none
* @return string token
*/
export async function create_jwt_token() {
  let user = await Auth.currentAuthenticatedUser();
  let token = user.signInUserSession.idToken.jwtToken;
  return token;
}

/* Create private link, 
 * @param string type - upload | download
 * @return string uuid
 */
export async function create_private_link( type, user ) {
  let jwtToken = await create_jwt_token();
  
  let uuid = await axios({
    method: 'post',
    headers: { 'Content-Type': 'application/json'},
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/createlink",
    json: true,
    data: {
      type: type
    },
    headers: {
      Authorization: jwtToken 
    },
  });
  
  return uuid; 
}

/* Create private upload link, 
 * @param string uuid
 * @param object file
 * @return string url
 */
export async function get_presigend_upload_URL( uuid, file ) {
   let jwtToken = await create_jwt_token();

   let url = await axios({
    method: 'post',
    headers: { 'Content-Type': 'application/json'},
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/getuploadurl",
    json: true,
    data: {
      uuid: uuid,
      file: file,
    },
    headers: {
      Authorization: jwtToken 
    },
  });

  return url;
}

/* Create private multipart upload link, 
 * @param string uuid
 * @param object file
 * @param number parts
 * @return json urls
 */
export async function get_presigend_multipart_upload_URL( uuid, file, parts ) {
  let jwtToken = await create_jwt_token();

  let urls = await axios({
   method: 'post',
   headers: { 'Content-Type': 'application/json'},
   url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/getmultipartuploadurl",
   json: true,
   data: {
     uuid: uuid,
     file: file,
     parts: parts,
   },
   headers: {
     Authorization: jwtToken 
   },
 });

 return urls;
}

/* Complete multipart upload, 
 * @param string uploadid
 * @param object parts
 * @return string ret
 */
export async function complete_presigend_multipart_upload( uuid, file, uploadid, parts ) {
  let jwtToken = await create_jwt_token();

  let ret = await axios({
   method: 'post',
   headers: { 'Content-Type': 'application/json'},
   url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/completemultipartupload",
   json: true,
   data: {
     uuid: uuid,
     file: file,
     uploadid: uploadid,
     parts: parts
   },
   headers: {
     Authorization: jwtToken 
   },
 });

 return ret;
}

/* Create PUBLIC Download link, 
 * @param string type - upload | download
 * @return string uuid
 */
export async function create_public_download_upload_uuid( type, clientemail, comment ) {
  let jwtToken = await create_jwt_token();

  let uuid = await axios({
    method: 'post',
    headers: { 'Content-Type': 'application/json'},
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/createlink",
    json: true,
    data: { type: type, clientemail: clientemail, comment: comment },
    headers: {
      Authorization: jwtToken 
    },
  });
  
  return uuid; 
}

/* Create PUBLIC UPLOAD link, 
 * @param string uuid
 * @param object file
 * @return string url
 */
export async function get_public_presigend_upload_URL( uuid, file ) {
   let url = await axios({
    method: 'post',
    headers: { 'Content-Type': 'application/json'},
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/public/getuploadurl",
    json: true,
    data: {
      uuid: uuid,
      file: file
    }
  });

  return url;
}

/* Get PRIVATE Files, 
 * @param string uuid
 * @return object {uuid: (array)files}
 */
export async function get_private_file_list(uuid){
  let jwtToken = await create_jwt_token();

  // should return list of filenames
  let list = await axios({
    method: 'post',
     headers: {
      Authorization: jwtToken 
    },
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/getfilelist",
    data: {
      uuid: uuid
    }
  });
  //console.log(list)
  return {[uuid]: list};
}


/* Get PRIVATE UUIDs, 
 * @return array uuids
 */
export async function get_private_UUID_list() {
  let jwtToken = await create_jwt_token();
  let uuids = await axios({
    method: 'post',
    headers: {
      Authorization: jwtToken 
    },
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/getuuidlist",
  });

  return uuids;
}

/* Toggle PRIVATE Link Type, 
 * @param string uuid
 * @return string newType
 */
export async function toggle_private_link_type(uuid) {
  let jwtToken = await create_jwt_token();
  let newType = await axios({
    method: 'post',
    headers: {
      Authorization: jwtToken 
    },
    url: "https://ih2qqbylbb.execute-api.eu-central-1.amazonaws.com/dev/private/toggletype",
    json: true,
    data: {
      uuid: uuid,
    }
  });

  return newType;
}
