// import useEffect hook
import React, { useState, useEffect } from 'react';
import {Menu, Container } from 'semantic-ui-react'
import { NavLink, BrowserRouter, Route, Switch, } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

import { Auth, Hub } from 'aws-amplify'

import MenuGenerateLink from './components/menuGenerateLink'
import LinkList from './components/LinkList'

// global public URLs
export const URL = { dl_url: "https://dax.acs-solution.de/dl/", ul_url: "https://dax.acs-solution.de/ul/"};

function signOut() {
  Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
}

function App(props) {
  const [user, setUser] = useState(null);

  function checkUser(func) {
    Auth.currentAuthenticatedUser()
      .then(user => setUser(user.username))
      .catch(err => setUser(null));
  }

  // in useEffect, we create the listener
  useEffect(() => {
    checkUser();

    Hub.listen('auth', (data) => {
      const { payload } = data
      console.log('A new auth event has happened: ', data)
       if (payload.event === 'signIn') {
          console.log('a user has signed in!')
          checkUser()
       }
       if (payload.event === 'signOut') {
          console.log('a user has signed out!')
       }
    })
  }, [])

  return (
    <div className="App">
      {user ? (
        <BrowserRouter>
          <div className="ui secondary pointing menu container">
            <div><img id="logo" src={logo} alt="acs logo" /></div>
            <NavLink activeClassName="active" className="item" exact to="/"> Link generieren </NavLink>
            <NavLink activeClassName="active" className="item" to="/mylinks"> Meine Links </NavLink>
            <Menu.Menu position='right'>
              <Menu.Item> {user} </Menu.Item>
              <Menu.Item
                name='logout'
                onClick={signOut}
              />
            </Menu.Menu>

          </div>
            <Switch>
              <Container>
                <Route exact path="/" component={MenuGenerateLink}></Route>
                <Route path="/mylinks" component={LinkList}></Route>
              </Container>
            </Switch>
        </BrowserRouter>
      ) : (
        <div className="ui middle aligned center aligned grid login">
          <div className="column">
            <h1 className="ui teal center aligned icon header">
              <i className="id grey badge icon"></i>
              DAta eXchange
              <div className="sub inverted header">ACS Solution GmbH</div>
            </h1>
            <button className="massive ui basic inverted button" onClick={() => Auth.federatedSignIn({provider: 'Microsoft365'})}>
              anmelden
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App