import React from 'react'
import { Form, Segment, Header, Icon  } from 'semantic-ui-react'
import { create_public_download_upload_uuid } from './aws_api_calls'
import {isValidUUID} from '../helper_functions.js'

// Master dax URL
import {URL} from '../App.js'

class CustomerUploadLink extends React.Component {
  state = { uploadUUIDs: [] }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { comment, email } = this.state
    const fetchUUID = async () => {
      var uuid = ''
      create_public_download_upload_uuid('upload', email, comment).then( (resp) => {
        uuid = resp.data.uuid
        if (isValidUUID(uuid)){ this.setState({uploadUUIDs: [...this.state.uploadUUIDs, uuid]}) } 

        else { alert('Antwort vom Server war komisch...' + resp.data.errorMessage) }
      })
    }

    fetchUUID();
  }

  render() {
    const { comment, email } = this.state

    return (
      <Segment  style={{'clear':'both'}}>
        <Form onSubmit={this.handleSubmit}>
            <Form.Input
              fluid label='Bemerkung'
              placeholder='Bemerkung'
              name='comment'
              value={comment}
              onChange={this.handleChange}
            />
            <Form.Input
              fluid label='Empfänger'
              placeholder='eMail-Adresse'
              name='email'
              value={email}
              onChange={this.handleChange}
            />
            <Form.Button  primary icon labelPosition='left'>
              <Icon name='plus' />
              Upload Link erstellen
            </Form.Button>
            <br></br><br></br>
        </Form>

         { this.state.uploadUUIDs.length > 0 &&
          <Segment>
            <Header size='medium'>neue Uploadlinks:</Header>
              {this.state.uploadUUIDs.map((uuid,index) =>
                <div className="row" key={index}>
                    <a href={URL.ul_url + uuid} target="_blank" rel="noopener noreferrer">{URL.ul_url + uuid}</a>
                </div>
              )}
          </Segment>
        }
      </Segment>
    )
  }
}

export default CustomerUploadLink;