/* eslint-disable */
// New Config File - AD-Auth

const config = {
    Auth: {
        IdentityPoolId: 'eu-central-1:76ac2f93-f107-47e6-996d-1e9dfa8aca34',
        region: 'eu-central-1',
        userPoolId:'eu-central-1_rKoDz7ZbJ',
        userPoolWebClientId: "6k964tg6q0pap6db88kjr84v5a",
        oauth: {
            domain: 'acsdax.auth.eu-central-1.amazoncognito.com',
            scope: ['email', 'openid', 'phone'],
            redirectSignIn: 'https://dax.acs-solution.de',
            redirectSignOut: 'https://dax.acs-solution.de',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
}

export default config;
